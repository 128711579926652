import { Layout } from './Layout';
import { SopViewer } from './components/SopViewer';
import { VideoArea } from './components/VideoArea';
import { UserStatus } from './components/UserStatus';
import { Chat } from './components/Chat';
import { OwnVideo } from './components/OwnVideo';

/**
 * Layout for desktop screen
 */
export class DesktopLayout extends Layout {
  constructor (width, height, guiSettings, isOwner, participants, hiddenUser) {
    super(width, height, 16, guiSettings, isOwner, participants, hiddenUser);

    // Use different layout, if the sop viewer is hidden
    const rightHidden = this.guiSettings.hideSopViewer;
    if (rightHidden) {
      this.calcColumns({ left: 1 / 4, center: 3 / 4, right: 0 });
    } else {
      this.calcColumns({ left: 1 / 3, center: 1 / 3, right: 1 / 3 });
    }

    this.ownVideoHeight = this.hiddenUser ? 0 : 150;
    this.chat = new Chat(this, 'left').toJson();
    this.videoArea = new VideoArea(this, 'center').toJson();
    this.userStatus = new UserStatus(this, 'left').toJson();
    this.ownVideo = new OwnVideo(this, 'left');
    this.sopViewer = new SopViewer(this, 'right').toJson();
  }
}
