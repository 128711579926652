/**
 * Layout manager
 * Get layout for screen size
 */
import { MobilePortraitLayout } from './MobilePortraitLayout';
import { MobileLandscapeLayout } from './MobileLandscapeLayout';
import { DesktopLayout } from './DesktopLayout';

export class LayoutManager {
  /**
   * Calculate layout for screen size
   * @param guiSettings GuiSettings from api
   * @param isOwner Is the user the owner of the call
   * @param participants Amount of call participants
   * @param hiddenUser Current user is hidden
   */
  static calcLayout ({ guiSettings, isOwner, participants, hiddenUser }) {
    const width = window.document.documentElement.clientWidth;
    const height = window.document.documentElement.clientHeight;

    const Layout = this.getLayout(width);
    return new Layout(width, height, guiSettings, isOwner, participants, hiddenUser).getLayout();
  }

  /**
   * Get the layout for screen size
   * @param width screen width in px
   * @returns {MobileLandscapeLayout|DesktopLayout|MobilePortraitLayout}
   */
  static getLayout (width) {
    // Find layout for screen size
    if (width < 576) {
      return MobilePortraitLayout;
    } else if (width < 992) {
      return MobileLandscapeLayout;
    } else {
      return DesktopLayout;
    }
  }
}
