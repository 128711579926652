import store from '../store/index';

/**
 * The own user as a participant of the video room
 */
export default class Myself {
  constructor (connection) {
    // Stream is ready to be viewed
    this.streamReady = false;
    // Video is active
    this.videoActive = false;
    // Audio is active
    this.audioActive = false;
    // Connection id
    this.id = connection.connectionId;
    // Server side data of the user, like the name
    this.data = JSON.parse(connection.data);

    // Device ID of the active cam
    this.activeCamera = null;
    // Device ID of the active mic
    this.activeMicrophone = null;
    // Mirror the own video image
    this.mirror = false;

    // Server side audio amplification settings
    this.audioAmplification = 1;
    this.audioClipMethode = 'clip';

    // Video quality settings, good quality/bandwidth ratio
    this.resolution = '640x480';
    this.frameRate = 15;

    // If user is not a hidden user send audio and video to server
    if (!this.data.hidden) {
      this.sendVideoAndAudio();
    }
  }

  /**
   * Get the video/audio stream
   * @returns {any}
   */
  getStream () {
    return this.publisher.stream.mediaStream;
  }

  /**
   * Set callback for events that should be handled in the ui
   * @param eventHandler
   */
  setEventHandler (eventHandler) {
    this.eventHandler = eventHandler;
  }

  /**
   * Handle UI changed on camera settings
   * @param cameraSettings
   */
  changeCameraSettings (cameraSettings) {
    console.log(cameraSettings);
    this.mirror = cameraSettings.mirrored;
    this.publisher.publishVideo(!cameraSettings.disabled);
    this.videoActive = !cameraSettings.disabled;

    /**
     * If video device changed, change video track
     */
    if (cameraSettings.selectedDevice !== this.activeCamera) {
      this.changeVideoDevice(cameraSettings.selectedDevice);
    }
  }

  /**
   * Handle UI changed on microphone settings
   * @param microphoneSettings
   */
  changeMicrophoneSettings (microphoneSettings) {
    console.log(microphoneSettings);
    this.publisher.publishAudio(!microphoneSettings.disabled);
    this.audioActive = !microphoneSettings.disabled;

    /**
     * If microphone device changed, change microphone track
     */
    if (microphoneSettings.selectedDevice !== this.activeMicrophone) {
      this.changeAudioDevice(microphoneSettings.selectedDevice);
    }
  }

  /**
   * Change audio device
   * @param audioDeviceId
   */
  changeAudioDevice (audioDeviceId = undefined) {
    // Stop the existing stream
    this.publisher.stream.mediaStream.getAudioTracks()[0].stop();
    // Get stream of the requested device
    store.state.room.OV.getUserMedia({
      audioSource: audioDeviceId,
      videoSource: false
    })
      .then(mediaStream => {
        // Get track and device id
        const audioTrack = mediaStream.getAudioTracks()[0];
        const deviceId = audioTrack.getSettings().deviceId;

        // Replace the audio track of the stream
        this.publisher.replaceTrack(audioTrack)
          .then(() => {
            // If mic. changed, update the id of the used device to show in UI
            this.activeMicrophone = deviceId;
            // Disable own audio playback to prevent audio feedback
            this.publisher.videoReference.muted = true;
            console.log('New track is being published');
          })
          .catch((error) => {
            console.error('Error replacing track');
            console.error(error);
          });
      });
  }

  /**
   * Change server side audio amplification
   * @param amplification
   */
  changeAudioAmplification (amplification) {
    // Remove existing audio filter
    this.publisher.stream.removeFilter()
      .then(() => {
        console.log('Filters removed');
      })
      .catch(error => {
        console.error(error);
      });

    // Add new audio filter with new amplification value
    this.publisher.stream.applyFilter('GStreamerFilter', { command: 'audioamplify amplification=' + amplification + ' clipping-method=' + this.audioClipMethode })
      .then(() => {
        this.audioAmplification = amplification;
        console.log('audio volume changed!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   * Change video device
   * @param videoDeviceId
   */
  changeVideoDevice (videoDeviceId = undefined) {
    // Stop the current video track
    this.publisher.stream.mediaStream.getVideoTracks()[0].stop();
    // Get stream of the requested device
    store.state.room.OV.getUserMedia({
      audioSource: false,
      videoSource: videoDeviceId,
      resolution: this.resolution,
      frameRate: this.frameRate
    })
      .then(mediaStream => {
        // Get track and device id
        const videoTrack = mediaStream.getVideoTracks()[0];
        const deviceId = videoTrack.getSettings().deviceId;

        // Replace the video track of the stream
        this.publisher.replaceTrack(videoTrack)
          .then(() => {
            // Update the id of the used device to show in UI
            this.activeCamera = deviceId;
            console.log('New track is being published');
          })
          .catch((error) => {
            console.error('Error replacing track');
            console.error(error);
          });
      });
  }

  /**
   * Send audio and video to the server
   * @param videoDeviceId
   * @param audioDeviceId
   */
  sendVideoAndAudio (videoDeviceId = undefined, audioDeviceId = undefined) {
    const publisherProperties = {
      audioSource: videoDeviceId,
      videoSource: audioDeviceId,
      publishAudio: true,
      publishVideo: true,
      resolution: this.resolution,
      frameRate: this.frameRate,
      insertMode: 'REPLACE',
      mirror: false,
      filter: {
        type: 'GStreamerFilter',
        options: {
          // server side audio amplification
          command: 'audioamplify amplification=' + this.audioAmplification + ' clipping-method=' + this.audioClipMethode
        }
      }
    };

    /**
     * Handle completed publishing of video and audio stream
     * @param error
     */
    const completionHandler = (error) => {
      if (error) {
        // Publishing video and audio failed, e.g. denied permission
        console.error('Error while initializing publisher: ', error);
        this.eventHandler({ type: 'videoAudioError', error: error });
        this.streamReady = false;
      } else {
        this.streamReady = true;
        this.activeCamera = this.publisher.stream.mediaStream.getVideoTracks()[0].getSettings().deviceId;
        this.activeMicrophone = this.publisher.stream.mediaStream.getAudioTracks()[0].getSettings().deviceId;
        console.log('Publisher successfully initialized');
      }
    };

    this.publisher = store.state.room.OV.initPublisher(undefined, publisherProperties, completionHandler);
    // TODO listen to 'accessDialogOpened' event to show user modal how to grant permission to audio and video
    store.state.room.session.publish(this.publisher);
  }
}
