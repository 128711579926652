import Vue from 'vue';

/**
 * Helper function to scroll to the end of the chat
 */
export default function scrollChatToBottom () {
  Vue.nextTick(() => {
    const elements = document.getElementsByClassName('chat-message-list');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const parent = element.parentElement;
      parent.scrollTop = parent.scrollHeight;
    }
  });
}
