<template>
   <div ref="videobox" class="p-box rounded shadow">
     <div class="ipcam-overlay" v-if="participant.data.type==='IPCAM' && participant.data.controllable">
       <b-dropdown no-caret>
         <template #button-content>
           <b-icon-arrows-move></b-icon-arrows-move>
         </template>
         <b-dropdown-text>
            Kamerasteuerung
         </b-dropdown-text>
         <b-dropdown-item @click="moveCam('UP')"><b-icon-arrow-up-circle-fill></b-icon-arrow-up-circle-fill> Hoch</b-dropdown-item>
         <b-dropdown-item @click="moveCam('DOWN')"><b-icon-arrow-down-circle-fill></b-icon-arrow-down-circle-fill> Runter</b-dropdown-item>
         <b-dropdown-item @click="moveCam('LEFT')"><b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill> Links</b-dropdown-item>
         <b-dropdown-item @click="moveCam('RIGHT')"><b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill> Rechts</b-dropdown-item>
       </b-dropdown>
     </div>
     <div class="fullscreen-overlay" v-if="fullscreenSupported && !own">
       <b-button variant="primary" v-if="!fullscreenEnabled" @click="openFullscreen">
         <b-icon-arrows-fullscreen variant="white"></b-icon-arrows-fullscreen>
       </b-button>
       <b-button variant="primary" v-if="fullscreenEnabled" @click="closeFullscreen">
         <b-icon-fullscreen-exit variant="white"></b-icon-fullscreen-exit>
       </b-button>
     </div>
       <div class="p-row p-header">
           <div v-if="participant.streamReady" class="d-flex w-100" style="position: relative">
               <video
                       ref="videoPlayer"
                       :srcObject.prop="participant.getStream()"
                       autoplay
                       :muted="muted"
                       :controls="controls"
                       class="video-player"
                       playsinline
                       v-bind:class="{ mirror: participant.mirror }"
               ></video>
               <div class="no-video" v-if="!participant.videoActive">
                   <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <p class="h1 mb-2"><b-icon-camera-video-off-fill></b-icon-camera-video-off-fill></p>
                   </div>
               </div>
           </div>
           <div v-else class="w-100 h-100 d-flex align-items-center text-center justify-content-center">
               <div>
                   <b-spinner label="Loading..."></b-spinner>
               </div>
           </div>
       </div>

       <div class="name-overlay">
           {{ participant.data.name }} {{ participant.data.sub_name ? " - "+participant.data.sub_name:"" }}
       </div>

       <div v-if="!own && !participant.audioActive" class="audio-muted-overlay">
           <b-icon-mic-mute-fill></b-icon-mic-mute-fill>
       </div>
   </div>

</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      controls: false,
      muted: this.own,

      // Is full screen supported by the browser
      fullscreenSupported: false,
      // is full screen active
      fullscreenEnabled: false
    };
  },
  created () {
    if (!this.own) {
      window.addEventListener('webkitfullscreenchange', this.fullscreenChange);
      window.addEventListener('fullscreenchange', this.fullscreenChange);

      if (document.fullscreenEnabled) {
        this.fullscreenSupported = document.fullscreenEnabled;
      } else if (document.webkitFullscreenEnabled) {
        this.fullscreenSupported = document.webkitFullscreenEnabled;
      }
    }
  },
  destroyed () {
    if (!this.own) {
      // Remove listeners
      window.removeEventListener('webkitFullscreenchange', this.fullscreenChange);
      window.removeEventListener('fullscreenchange', this.fullscreenChange);
    }
  },
  methods: {
    /**
     * Open full screen view
     */
    openFullscreen () {
      const target = this.$refs.videobox;
      if (target.requestFullscreen) {
        target.requestFullscreen();
      } else if (target.webkitRequestFullscreen) {
        target.webkitRequestFullscreen();
      }
    },
    /**
     * Close full screen view
     */
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },


    /**
     * Update the fullscreen status, called by event listener
     */
    fullscreenChange (event) {
      if (document.fullscreenElement !== undefined) {
        console.log(document.fullscreenElement);
        this.fullscreenEnabled = this.$refs.videobox.isSameNode(document.fullscreenElement);
      } else if (document.webkitFullscreenElement !== undefined) {
        this.fullscreenEnabled = this.$refs.videobox.isSameNode(document.webkitFullscreenElement);
      }
    },

    /**
     * Adjust muted state of the video player
     */
    adjustMuted: function () {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.muted = (this.muted || this.speakerMuted);
      }
    },

    /**
     * Move ip cam to a given direction
     * @param direction
     */
    moveCam: function (direction) {
      axios.put('ipcams/' + this.participant.data.id, { direction: direction })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          // TODO error management
          console.error('Moving cam failed');
          console.error(error);
        })
        .finally(() => {

        });
    }
  },
  computed: {

    ...mapState([
      'speakerVolume',
      'speakerMuted'
    ])

  },
  props: {
    participant: {
      type: Object,
      required: true
    },
    footerControls: {
      type: Boolean,
      required: false,
      default: false
    },
    own: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    muted: function () {
      this.adjustMuted();
    },
    speakerMuted: function () {
      this.adjustMuted();
    },
    /**
     * Set video player volume to a value from 0 to 1, based on the global speaker percentage value
     */
    speakerVolume: function () {
      this.$refs.videoPlayer.volume = this.speakerVolume / 100;
    }
  }
};
</script>
