import { Component } from './Component';

export class Main extends Component {
  constructor (layout) {
    super();
    this.top = layout.menuBar.height;
    this.width = layout.width;
    this.height = layout.height - layout.footer.height - layout.menuBar.height - layout.mediaControl.height;
  }
}
