export class ConfigError extends Error {
  constructor (code = 0, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConfigError);
    }

    this.code = code;
  }
}
