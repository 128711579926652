import { Component } from './Component';

export class Chat extends Component {
  constructor (layout, column, mobile = false) {
    super();

    const expanded = layout.guiSettings.expandChat && !layout.hideChat;

    this.hidden = layout.hideUserStatus;
    this.top = expanded || !mobile ? layout.ownVideoHeight : Math.ceil(layout.main.height / 2);
    this.left = layout.columns[column].left;
    this.width = layout.columns[column].width;
    this.height = expanded || !mobile ? layout.main.height - layout.ownVideoHeight : Math.ceil(layout.main.height / 2);
    this.expanded = expanded;
    this.showNavIcon = true;
    this.showScrollIcon = !mobile;
    this.showExpand = mobile;
    this.allowClose = mobile;
    this.hidden = layout.hideChat;
  }

  toJson () {
    return {
      top: this.top,
      left: this.left,
      width: this.width,
      height: this.height,
      hidden: this.hidden,
      expanded: this.expanded,
      showNavIcon: this.showNavIcon,
      showScrollIcon: this.showScrollIcon,
      showExpand: this.showExpand,
      allowClose: this.allowClose
    };
  }
}
