import Vue from 'vue';
import Vuex from 'vuex';
import chat from './modules/chat';
import userStatus from './modules/userStatus';
import room from './modules/room';
import { LayoutManager } from '../layout/LayoutManager';

Vue.use(Vuex);

/**
 * Global state management
 */
export default new Vuex.Store({

  modules: {
    chat,
    userStatus,
    room
  },

  mutations: {

    /**
     * Set the speakers volume
     * @param state
     * @param volume
     */
    setSpeakerVolume (state, volume) {
      state.speakerVolume = volume;
    },

    /**
     * Set the speakers muted state
     * @param state
     * @param muted
     */
    setSpeakerMuted (state, muted) {
      state.speakerMuted = muted;
    },

    /**
     * Set the application config (loaded from the api)
     * @param state
     * @param config
     */
    setConfig (state, config) {
      state.config = config;
    },

    /**
     * Set a single gui setting
     * @param state
     * @param setting Name of the setting
     * @param value Value of the setting
     */
    setGuiSetting (state, { setting, value }) {
      state.guiSettings[setting] = value;
    },

    /**
     * Toggle a boolean gui setting
     * @param state
     * @param setting Name of the setting
     */
    toggleGuiSetting (state, setting) {
      state.guiSettings[setting] = !state.guiSettings[setting];
    },

    /**
     * Recalculate the layout for the current screen size, gui settings, etc.
     * @param state
     */
    updateLayout (state) {
      state.layout = LayoutManager.calcLayout({ guiSettings: state.guiSettings, isOwner: (state.config.loaded && state.config.myself.id === state.config.owner.id), participants: state.room.participants.length, hiddenUser: (state.room.ownUser != null && state.room.ownUser.data.hidden) });
    }
  },
  state: {
    // Speaker settings
    speakerVolume: 100,
    speakerMuted: false,

    // Application layout (calc. by the layout manager)
    layout: {},

    // Config loaded from the alarm server api
    config: {
      loaded: false,
      timestamp: null,
      serverData: null,
      openViduToken: null,
      record: 0,
      keepChat: false,
      myself: null,
      owner: null,
      documents: []
    },

    // Gui settings loaded from the alarm server and changed during runtime by the UI
    guiSettings: {
      hideMenuBar: false,
      bigButtons: false,
      hideChat: true,
      expandChat: false,
      hideSopViewer: false,
      hideUserStatus: false,
      showTime: true,
      autoDisable: false
    }
  },

  getters: {
    /**
     * Is the current user the owner of the call
     * @param state
     * @returns {boolean}
     */
    isOwner: state => {
      return state.config.loaded && state.config.myself.id === state.config.owner.id;
    }
  }
});
