import { Component } from './Component';

export class SopViewer extends Component {
  constructor (layout, column, hidden = false) {
    super();

    this.hidden = hidden || layout.guiSettings.hideSopViewer;
    this.left = layout.columns[column].left;
    this.width = layout.columns[column].width;
    this.height = layout.main.height;
  }
}
