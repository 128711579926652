import axios from 'axios';

const state = () => ({
  messages: [],
  sendingMessage: false
});

const getters = {
  /**
   * Get amount of unconfirmed messages
   * @param state
   * @param getters
   * @param rootState
   * @returns {number}
   */
  unconfirmedMessages (state, getters, rootState) {
    return state.messages.filter(message => {
      return message.user.id !== rootState.config.myself.id && !message.confirmations.some(confirmation => confirmation.user.id === rootState.config.myself.id);
    }).length;
  }
};

const actions = {

  /**
   * Send new chat message
   * @param dispatch
   * @param commit
   * @param state
   * @param newMessage
   * @returns {Promise<unknown>}
   */
  async sendMessage ({ dispatch, commit, state }, newMessage) {
    state.sendingMessage = true;
    return new Promise((resolve, reject) => {
      axios.post('messages', { message: newMessage })
        .then(response => {
          resolve();
        })
        .catch(error => {
          // TODO error management
          reject(error);
        })
        .finally(() => {
          state.sendingMessage = false;
        });
    });
  }

};

const mutations = {
  /**
   * Add message to chat
   * @param state
   * @param newMessage
   */
  addMessage (state, newMessage) {
    if (state.messages.some(message => message.id === newMessage.id)) { return; }
    state.messages.push(newMessage);
    state.messages.sort((a, b) => {
      return (new Date(a.datetime).valueOf() >= new Date(b.datetime).valueOf()) ? 1 : -1;
    });
  },

  /**
   * Add confirmation to chat
   * @param state
   * @param newConfirmation
   */
  addConfirmation (state, newConfirmation) {
    state.messages.forEach(message => {
      if (message.id === newConfirmation.message) {
        if (message.confirmations.some(confirmation => confirmation.id === newConfirmation.id)) { return; }
        message.confirmations.unshift(newConfirmation);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
