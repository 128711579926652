<template>
    <b-button-group class="media-control-buttons">
        <b-dropdown v-if="!ownUser.data.hidden" :variant="camera.disabled ? 'warning' : 'secondary'" class="m-0 p-0">
            <template #button-content>
                <b-icon-camera-video-off-fill v-if="camera.disabled"></b-icon-camera-video-off-fill>
                <b-icon-camera-video-fill v-else></b-icon-camera-video-fill>
            </template>
            <b-dropdown-text text-class="font-weight-bold">Kamera</b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :active="camera.mirrored" @click="camera.mirrored = !camera.mirrored"><b-icon-arrow-repeat></b-icon-arrow-repeat> Spiegeln</b-dropdown-item>
            <b-dropdown-item v-if="!camera.disabled" @click="camera.disabled = !camera.disabled"><b-icon-camera-video-off-fill></b-icon-camera-video-off-fill> Deaktivieren</b-dropdown-item>
            <b-dropdown-item v-else @click="camera.disabled = !camera.disabled"><b-icon-camera-video-fill></b-icon-camera-video-fill> Aktivieren</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item v-for="device in camera.devices" :key="device.deviceId" :active="device.deviceId === ownUser.activeCamera" @click="camera.selectedDevice = device.deviceId" >{{device.label}}</b-dropdown-item>
            <b-dropdown-divider v-if="ipCams.length>0" ></b-dropdown-divider>
            <template v-if="ipCams.length>0">
                <b-dropdown-text text-class="font-weight-bold">Externe Kameras</b-dropdown-text>
                <b-dropdown-item v-for="ipCam in ipCams" :key="ipCam.id" :active="connectedIpCams.some(cam => cam.data.id === ipCam.id)" @click="toggleIpCam(ipCam)" >{{ipCam.name}}</b-dropdown-item>
            </template>

        </b-dropdown>
        <b-dropdown v-if="!ownUser.data.hidden" :variant="microphone.disabled ? 'warning' : 'secondary'" class="m-0 p-0">
            <template #button-content>
                <b-icon-mic-mute-fill v-if="microphone.disabled"></b-icon-mic-mute-fill>
                <b-icon-mic-fill v-else></b-icon-mic-fill>
            </template>
            <b-dropdown-text text-class="font-weight-bold">Mikrofon</b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :active="microphone.disabled" @click="microphone.disabled = !microphone.disabled"><b-icon-mic-mute-fill></b-icon-mic-mute-fill> Deaktivieren</b-dropdown-item>
            <button class="dropdown-item user-select-none" :disabled="ownUser.audioAmplification==8" v-on:click.stop="ownUser.changeAudioAmplification(ownUser.audioAmplification*2)" ><b-icon-caret-up-fill></b-icon-caret-up-fill> Lauter</button>
            <button class="dropdown-item user-select-none" :disabled="ownUser.audioAmplification==1" v-on:click.stop="ownUser.changeAudioAmplification(ownUser.audioAmplification/2)"><b-icon-caret-down-fill></b-icon-caret-down-fill> Leiser</button>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item v-for="device in microphone.devices" :key="device.deviceId" :active="device.deviceId === ownUser.activeMicrophone" @click="microphone.selectedDevice = device.deviceId" >{{device.label}}</b-dropdown-item>

        </b-dropdown>
        <b-dropdown :variant="speakerMuted ? 'warning' : 'secondary'" class="m-0 p-0">
            <template #button-content>
                <b-icon-volume-mute-fill v-if="speakerMuted"></b-icon-volume-mute-fill>
                <b-icon-volume-up-fill v-else></b-icon-volume-up-fill>
            </template>
            <b-dropdown-text text-class="font-weight-bold">Lautsprecher</b-dropdown-text>
            <b-dropdown-text v-if="!disabledVolumeControl" text-class="font-weight-normal">{{speakerVolume }}%</b-dropdown-text>
            <b-dropdown-divider v-if="!disabledVolumeControl" ></b-dropdown-divider>
            <b-dropdown-item :active="speakerMuted" @click="$store.commit('setSpeakerMuted', !speakerMuted)"><b-icon-volume-mute-fill></b-icon-volume-mute-fill> Stummschalten</b-dropdown-item>
            <b-dropdown-item v-if="!disabledVolumeControl"  :disabled="speakerVolume==100" v-on:click="$store.commit('setSpeakerVolume', 100)"><b-icon-volume-up-fill></b-icon-volume-up-fill> Max.</b-dropdown-item>
            <button v-if="!disabledVolumeControl" class="dropdown-item user-select-none" :disabled="speakerVolume==100" v-on:click.stop="$store.commit('setSpeakerVolume', speakerVolume + 10)" ><b-icon-caret-up-fill></b-icon-caret-up-fill> Lauter</button>
            <button v-if="!disabledVolumeControl" class="dropdown-item user-select-none" :disabled="speakerVolume==0" v-on:click.stop="$store.commit('setSpeakerVolume', speakerVolume - 10)"><b-icon-caret-down-fill></b-icon-caret-down-fill> Leiser</button>
        </b-dropdown>

        <b-button v-if="layout.sopViewer.hidden && !guiSettings.hideSopViewer" @click="showSopViewer = true"  class="m-0 p-0">
            <b-icon-file-earmark-richtext-fill></b-icon-file-earmark-richtext-fill>
        </b-button>
        <b-modal v-if="layout.sopViewer.hidden && !guiSettings.hideSopViewer" v-model="showSopViewer" size="xl" class="h-100" title="Dokumente" centered hide-footer :modal-class="{'big-buttons': guiSettings.bigButtons, 'modal-fullscreen': true} " >
            <sop-viewer></sop-viewer>
        </b-modal>

        <b-button @click="toggleUserStatus" class="m-0 p-0" v-if="layout.userStatus.showNavIcon" :variant="guiSettings.hideUserStatus ? 'secondary' : 'primary'">
            <b-icon-people-fill></b-icon-people-fill>
        </b-button>

        <b-button @click="toggleChat" class="m-0 p-0" v-if="layout.chat.showNavIcon" :variant="guiSettings.hideChat ? 'secondary' : 'primary'">
            <b-icon-chat-text-fill></b-icon-chat-text-fill> <b-badge v-if="unconfirmedMessages>0 && guiSettings.hideChat" variant="danger">{{ unconfirmedMessages }}</b-badge>
        </b-button>

    </b-button-group>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import SopViewer from './SopViewer';

export default {
  components: { SopViewer },
  data () {
    return {
      camera: {
        mirrored: false,
        disabled: false,
        selectedDevice: null,
        devices: []
      },
      microphone: {
        disabled: false,
        selectedDevice: null,
        devices: []
      },

      disabledVolumeControl: false,

      autoDisableLastSettings: {
        cameraDisabled: false,
        microphoneDisabled: false,
        speakerMuted: false
      },

      showSopViewer: false,
      showChatModal: false,
      showUserStatusModal: false

    };
  },
  methods: {

    /**
     * Toggle chat, either open user status instead or close completely (depends on layout settings)
     */
    toggleChat: function () {
      // Chat is hidden, make visible again and hide user status
      if (this.guiSettings.hideChat) {
        this.$store.commit('setGuiSetting', { setting: 'hideChat', value: false });
        this.$store.commit('setGuiSetting', { setting: 'hideUserStatus', value: true });
      } else {
        // Chat is visible, hide chat if allowed
        if (this.layout.chat.allowClose) {
          this.$store.commit('setGuiSetting', { setting: 'hideChat', value: true });
        }
      }
    },

    /**
     * Toggle user status, either open chat instead or close completely (depends on layout settings)
     */
    toggleUserStatus: function () {
      // User status is hidden, make visible again and hide chat
      if (this.guiSettings.hideUserStatus) {
        this.$store.commit('setGuiSetting', { setting: 'hideUserStatus', value: false });
        this.$store.commit('setGuiSetting', { setting: 'hideChat', value: true });
      } else {
        // User status is visible, hide user status if allowed
        if (this.layout.userStatus.allowClose) {
          this.$store.commit('setGuiSetting', { setting: 'hideUserStatus', value: true });
        }
      }
    },

    /**
     * Check if device is an iOS device
     * @returns {boolean|boolean}
     */
    isIOS: function () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
                    // iPad on iOS 13 detection
                    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },

    /**
     * Update list of video and audio devices
     */
    updateDeviceList: function () {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.camera.devices = [];
          this.microphone.devices = [];
          devices.forEach((device) => {
            device = JSON.parse(JSON.stringify(device));
            switch (device.kind) {
              case 'audioinput':
                this.microphone.devices.push(device);
                break;
              case 'videoinput':
                this.camera.devices.push(device);
                break;
            }
          });

          // If the current camera device is not found in the list, it doesn't exist anymore
          // therefore reset the camera
          if (!this.camera.devices.some((device) => device.deviceId === this.ownUser.activeCamera)) {
            this.ownUser.activeCamera = null;
          }

          // If the current microphone device is not found in the list, it doesn't exist anymore
          // therefore reset the microphone
          if (!this.microphone.devices.some((device) => device.deviceId === this.ownUser.activeMicrophone)) {
            this.ownUser.activeMicrophone = null;
          }
        });
    },

    /**
     * Publish/unpublish the given ipcam
     * @param ipCam
     */
    toggleIpCam: function (ipCam) {
      if (this.connectedIpCams.some(cam => cam.data.id === ipCam.id)) {
        this.$store.dispatch('room/unPublishIpCam', ipCam);
      } else {
        this.$store.dispatch('room/publishIpCam', ipCam);
      }
    }
  },
  mounted: function () {
    // Volume control is unavailable on ios devices, use hardware buttons instead
    this.disabledVolumeControl = this.isIOS();

    // Load list of video and audio devices and update list if device is plugged in/out
    navigator.mediaDevices.ondevicechange = () => {
      this.updateDeviceList();
    };
    this.updateDeviceList();

    /**
     * If auto disable on tab change is enabled, toggle video and audio for privacy reasons
     */
    if (this.guiSettings.autoDisable) {
      document.addEventListener('visibilitychange', () => {
        // If tab is visible again, reset settings to state before tab was invisible
        if (document.visibilityState === 'visible') {
          this.camera.disabled = this.autoDisableLastSettings.cameraDisabled;
          this.microphone.disabled = this.autoDisableLastSettings.microphoneDisabled;
          this.$store.commit('setSpeakerMuted', this.autoDisableLastSettings.speakerMuted);
        } else {
          // Save the current status of video, mic. and speaker
          this.autoDisableLastSettings.cameraDisabled = this.camera.disabled;
          this.autoDisableLastSettings.microphoneDisabled = this.microphone.disabled;
          this.autoDisableLastSettings.speakerMuted = this.speakerMuted;

          // Disable video, mic. and speaker
          this.camera.disabled = true;
          this.microphone.disabled = true;
          this.$store.commit('setSpeakerMuted', true);
        }
      });
    }

    // If the user is the owner of the room, load ip cams to allow sharing
    if (this.isOwner) {
      this.$store.dispatch('room/loadIpCams');
    }
  },
  watch: {
    'ownUser.activeCamera': function () {
      this.camera.selectedDevice = this.ownUser.activeCamera;
      this.camera.disabled = false;
    },
    'ownUser.activeMicrophone': function () {
      this.microphone.selectedDevice = this.ownUser.activeMicrophone;
      this.microphone.disabled = false;
    },
    'ownUser.streamReady': function () {
      this.updateDeviceList();
    },
    'camera.selectedDevice': function () {
      this.ownUser.changeCameraSettings(this.camera);
    },
    'camera.disabled': function () {
      this.ownUser.changeCameraSettings(this.camera);
    },
    'camera.mirrored': function () {
      this.ownUser.changeCameraSettings(this.camera);
    },
    'microphone.selectedDevice': function () {
      this.ownUser.changeMicrophoneSettings(this.microphone);
    },
    'microphone.disabled': function () {
      this.ownUser.changeMicrophoneSettings(this.microphone);
    }
  },
  computed: {
    ...mapState([
      'speakerVolume',
      'speakerMuted',
      'config',
      'guiSettings',
      'layout'
    ]),
    ...mapGetters('chat', [
      'unconfirmedMessages'
    ]),
    ...mapGetters([
      'isOwner'
    ]),
    ...mapGetters('room', [
      'connectedIpCams'
    ]),

    ...mapState('room', [
      'participants',
      'ownUser',
      'ipCams'
    ])
  }
};
</script>
