<template>
    <div v-bind:style="style" v-if="!hidden">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'LayoutComponent',
  props: [
    'name',
    'layout'
  ],
  computed: {
    /**
     * Set the css style attributes based on the calculated layout
     * @returns {boolean}
     */
    style: function () {
      return {
        position: 'absolute',
        top: this.layout[this.name].top + 'px',
        left: this.layout[this.name].left + 'px',
        height: this.layout[this.name].height + 'px',
        width: this.layout[this.name].width + 'px'
      };
    },
    /**
     * Check if the layout component should be visible
     * @returns {boolean}
     */
    hidden: function () {
      return this.layout[this.name].hidden === true;
    }
  }
};
</script>

<style scoped>

</style>
