import { Footer } from './components/Footer';
import { MediaControl } from './components/MediaControl';
import { MenuBar } from './components/MenuBar';
import { Main } from './components/Main';

/**
 * Basic abstract layout class
 */
export class Layout {
  /**
   * Width of the screen in px
   * @type {number}
   */
  width = 0;

  /**
   * Height of the screen in px
   * @type {number}
   */
  height = 0;

  /**
   * GuiSettings from the api
   * @type {*}
   */
  guiSettings = undefined;

  /**
   * User is the owner of the call
   * @type {boolean}
   */
  isOwner = false;

  /**
   * Amount of call participants
   * @type {number}
   */
  participants = 0;

  /**
   * User is hidden (video and audio not shown)
   * @type {boolean}
   */
  hiddenUser = false;

  /**
   * Calculated layout
   * @type {{}}
   */
  layout = {};

  /**
   * Build generic layout for all screen sizes
   * @param width Screen width in px
   * @param height Screen height in px
   * @param footerHeight Footer height in px
   * @param guiSettings GuiSettings from the api
   * @param isOwner User is the owner of the call
   * @param participants Amount of call participants
   * @param hiddenUser User is hidden (video and audio not shown)
   */
  constructor (width, height, footerHeight, guiSettings, isOwner, participants, hiddenUser) {
    // Set attributes
    this.width = width;
    this.height = height;
    this.guiSettings = guiSettings;
    this.isOwner = isOwner;
    this.participants = participants;
    this.hiddenUser = hiddenUser;

    // Calculate basic layout components
    this.footer = new Footer(this, footerHeight).toJson();
    this.mediaControl = new MediaControl(this).toJson();
    this.menuBar = new MenuBar(this).toJson();
    this.main = new Main(this).toJson();
    this.chat = {};
    this.videoArea = {};
    this.userStatus = {};
    this.sopViewer = {};
    this.otherVideos = { hideLabel: false };
    this.ownVideo = {};

    // Get gui settings
    this.hideChat = this.guiSettings.hideChat;
    this.hideUserStatus = this.guiSettings.hideUserStatus;
  }

  /**
   * Get the layout as json
   */
  getLayout () {
    return {
      body: { width: this.width, height: this.height },
      footer: this.footer,
      menuBar: this.menuBar,
      main: this.main,
      mediaControl: this.mediaControl,
      chat: this.chat,
      videoArea: this.videoArea,
      userStatus: this.userStatus,
      sopViewer: this.sopViewer,
      otherVideos: this.otherVideos,
      ownVideo: this.ownVideo
    };
  }

  /**
   * Calculate the position and width of the columns
   */
  calcColumns (columns) {
    this.columns = {
      left: {
        left: 0,
        width: Math.ceil(this.main.width * columns.left)
      },
      center: {
        left: Math.ceil(this.main.width * columns.left),
        width: Math.ceil(this.main.width * columns.center)
      },
      right: {
        left: Math.ceil(this.main.width * columns.left) + Math.ceil(this.main.width * columns.center),
        width: Math.ceil(this.main.width * columns.right)
      }
    };
  }
}
