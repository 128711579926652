<template>
    <div>
        <div v-if="alarm.status === 'alarmed' || alarm.status === 'received'">
            <b-button-group class="w-100">
                <b-button variant="success" @click="$emit('join')"><b-icon-check-circle-fill></b-icon-check-circle-fill></b-button>
                <b-button variant="warning" @click="$emit('feedback', 'accepted')"><b-icon-hourglass-split></b-icon-hourglass-split></b-button>
                <b-button variant="danger" @click="$emit('feedback', 'declined')"><b-icon-x-circle-fill></b-icon-x-circle-fill></b-button>
            </b-button-group>
        </div>
        <div v-if="alarm.status === 'declined'">
            <b-alert show variant="danger"><b-icon-x-circle-fill></b-icon-x-circle-fill> Abgelehnt</b-alert>
            <b-button variant="dark" block @click="$emit('join')" target="_blank"><b-icon-door-open-fill></b-icon-door-open-fill> Beitreten</b-button>
        </div>
        <div v-if="alarm.status === 'accepted'">
            <b-alert show variant="success"><b-icon-check-circle-fill></b-icon-check-circle-fill> Angenommen, komme später</b-alert>
            <b-button variant="dark" block @click="$emit('join')" target="_blank"><b-icon-door-open-fill></b-icon-door-open-fill> Beitreten</b-button>
        </div>
        <div v-if="alarm.status === 'joined'">
            <b-alert show variant="success"><b-icon-check-circle-fill></b-icon-check-circle-fill> Beigetreten</b-alert>
            <b-button variant="dark" block @click="$emit('join')" target="_blank"><b-icon-door-open-fill></b-icon-door-open-fill> Beitreten</b-button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'NewAlarmFeedback',
  props: {
    alarm: Object
  }
};
</script>
