import { Component } from './Component';

export class MediaControl extends Component {
  constructor (layout) {
    super();
    this.width = layout.width;
    this.height = layout.guiSettings.bigButtons ? 50 : 38;
    this.top = layout.height - layout.footer.height - this.height;
  }
}
