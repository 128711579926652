<template>
    <div>
        <b-card v-for="user in users" :key="user.id" class="p-2 mb-2" no-body >
            <div class="d-flex justify-content-between align-items-center mb-1">
                <div>
                    <strong>{{ user.name }}</strong><br>
                    <span v-if="user.sub_name">{{ user.sub_name }}</span>
                </div>
                <b-button type="button"  v-if="isOwner" @click="$store.dispatch('userStatus/alarmUser',{user: user, force: true})" :disabled="alarming" class="btn btn-danger">
                    <b-spinner small v-if="alarming && alarmUser && alarmUser.id === user.id" ></b-spinner>
                    <b-icon-bell-fill v-else ></b-icon-bell-fill>
                </b-button>
            </div>

            <b-badge v-if="user.status === 'alarmed'" variant="primary">Alarmiert ({{ new Date(user.updated_at).toLocaleTimeString()}})</b-badge>
            <b-badge v-if="user.status === 'received'" variant="secondary">Alarmierung empfangen ({{ new Date(user.updated_at).toLocaleTimeString()}})</b-badge>
            <b-badge v-if="user.status === 'declined'" variant="danger">Abgelehnt ({{ new Date(user.updated_at).toLocaleTimeString()}})</b-badge>
            <b-badge v-if="user.status === 'accepted'" variant="warning">Angenommen, kommt später ({{ new Date(user.updated_at).toLocaleTimeString()}})</b-badge>
            <b-badge v-if="user.status === 'joined'" variant="success">Beigetreten ({{ new Date(user.updated_at).toLocaleTimeString()}})</b-badge>
        </b-card>
        </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'UserStatus',

  computed: {

    ...mapState('userStatus', [
      'users',
      'alarming',
      'alarmUser'
    ]),

    ...mapGetters([
      'isOwner'
    ])
  }
};
</script>
