import axios from 'axios';
import { OpenVidu } from 'openvidu-browser/lib/index';

const state = () => ({
  participants: [],
  hiddenParticipants: [],
  ipCams: [],

  ownUser: null,
  OV: null,
  session: null,
  connected: false
});

const getters = {
  /**
   * Get list of all the ip cams that are currently active
   * @param state
   * @param getters
   * @returns {*[]}
   */
  connectedIpCams (state, getters) {
    return state.participants.filter(participant => participant.data.type === 'IPCAM');
  }
};

const actions = {

  /**
   * Add ip cam to video room
   * @param dispatch
   * @param commit
   * @param state
   * @param ipCam
   * @returns {Promise<AxiosResponse<any>>}
   */
  async publishIpCam ({ dispatch, commit, state }, ipCam) {
    // TODO error management
    return axios.post('ipcams/' + ipCam.id);
  },

  /**
   * Remove ip cam from video room
   * @param dispatch
   * @param commit
   * @param state
   * @param ipCam
   * @returns {Promise<AxiosResponse<any>>}
   */
  async unPublishIpCam ({ dispatch, commit, state }, ipCam) {
    // TODO error management
    return axios.delete('ipcams/' + ipCam.id);
  },

  /**
   * Load list of the ip cams available for the user
   * @param dispatch
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async loadIpCams ({ dispatch, commit, state }) {
    // TODO error management
    axios.get('ipcams')
      .then(response => {
        commit('setIpCams', response.data.data);
      });
  }

};

const mutations = {

  /**
   * Reset all the room settings
   * @param state
   */
  reset (state) {
    state.participants = [];
    state.hiddenParticipants = [];
    state.ipCams = [];
    state.ownUser = null;
    state.OV = null;
    state.session = null;
    state.connected = false;
  },

  /**
   * Initialize the room/OpenVidu
   * @param state
   */
  init (state) {
    state.OV = new OpenVidu();
    state.session = state.OV.initSession();
  },

  /**
   * Add stream to participant
   * @param state
   * @param stream
   */
  addStream (state, stream) {
    state.participants.forEach((participant) => {
      if (participant.id === stream.connection.connectionId) {
        participant.setStream(stream);
      }
    });
  },

  /**
   * Add participant
   * @param state
   * @param participant
   */
  addParticipant (state, participant) {
    state.participants.push(participant);
  },

  /**
   * Add hidden participant
   * @param state
   * @param participant
   */
  addHiddenParticipant (state, participant) {
    state.hiddenParticipants.push(participant);
  },

  /**
   * Remove participant
   * @param state
   * @param connection
   */
  removeParticipant (state, connection) {
    const data = JSON.parse(connection.data);

    // If user is hidden, remove from hidden participants list
    if (data.hidden) {
      state.hiddenParticipants = state.hiddenParticipants.filter(function (participant) {
        return participant.id !== connection.connectionId;
      });
    } else {
      state.participants = state.participants.filter(function (participant) {
        return participant.id !== connection.connectionId;
      });
    }
  },

  /**
   * Set status of the OpenVidu connection
   * @param state
   * @param connected
   */
  setConnected (state, connected) {
    state.connected = connected;
  },

  /**
   * Set the own user
   * @param state
   * @param ownUser
   */
  setOwnUser (state, ownUser) {
    state.ownUser = ownUser;
  },

  /**
   * Set list of available ip cams
   * @param state
   * @param ipCams
   */
  setIpCams (state, ipCams) {
    state.ipCams = ipCams;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
