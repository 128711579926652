<template>
    <div class="chat-message-list">
        <div class="text-muted text-center" v-if="config.keepChat">
            <b-icon-file-earmark-text-fill></b-icon-file-earmark-text-fill> <i>Dieser Chat wird protokolliert</i>
            <hr>
        </div>

        <b-card v-for="message in messages" :key="message.id" class="mt-2 message" no-body v-bind:class="{'from-me': isMyMessage(message), 'from-other': !isMyMessage(message)}">
            <div class="d-flex w-100 justify-content-between">
            <strong>{{ message.user.name }} {{ message.user.sub_name ? (" - "+message.user.sub_name) :"" }} {{isMyMessage(message) ? "(Ich)":"" }}</strong>
                <small>{{ new Date(message.datetime).toLocaleTimeString() }}</small>
            </div>
            {{ message.message }}

            <b-button variant="success" :disabled="confirmingMessage" v-if="!isMyMessage(message) && !confirmedMessage(message)" @click="confirmMessage(message)"><b-icon-check2-circle></b-icon-check2-circle> Empfang bestätigen</b-button>
            <div v-if="message.confirmations.length > 0"><b-icon-check2-circle></b-icon-check2-circle>
                <small v-for="(confirmation, index) in message.confirmations" :key="confirmation.id">
                            {{ confirmation.user.name }} ({{ new Date(confirmation.datetime).toLocaleTimeString() }})
                    <span v-if="index < (message.confirmations.length-1)">, </span>
                </small>
            </div>
        </b-card>
    </div>
</template>

<script>

import axios from 'axios';
import { mapState } from 'vuex';
import scrollChatToBottom from '../helpers/ChatHelper';

export default {
  name: 'ChatMessageList',
  data () {
    return {
      confirmingMessage: false
    };
  },

  methods: {
    /**
     * Check if the message was send by the current user
     * @param message
     * @returns {boolean}
     */
    isMyMessage: function (message) {
      return message.user.id === this.config.myself.id;
    },

    /**
     * Check if the current user already confirmed the message
     * @param message
     * @returns {*}
     */
    confirmedMessage: function (message) {
      return message.confirmations.some(confirmation => confirmation.user.id === this.config.myself.id);
    },

    /**
     * Confirm a message
     * @param message
     */
    confirmMessage: function (message) {
      this.confirmingMessage = true;

      axios.put('messages/' + message.id)
        .then(response => {
        })
        .catch((error) => {
          // TODO error management
          console.error('Error confirming message');
          console.error(error);
        })
        .finally(() => {
          this.confirmingMessage = false;
        });
    }
  },
  mounted: function () {
    // Scroll chat to bottom to show latest message
    scrollChatToBottom();
  },
  computed: {
    ...mapState('chat', [
      'messages'
    ]),
    ...mapState([
      'config'
    ])
  },
  watch: {
    // On new messages scroll chat to bottom to show latest message
    messages: {
      deep: true,
      handler () {
        scrollChatToBottom();
      }
    }
  }
};

</script>

<style scoped>

    .message {
        border-radius: 0.7rem;
        padding: 0.6rem;
    }

    .message::before,
    .message::after {
        bottom: 0rem;
        content: "";
        height: 1rem;
        position: absolute;
    }

    .from-me {
        background-color: #007bff;
        border-color: #007bff;
        color: #fff;
        margin-left: 1rem;
    }

    .from-me::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid #007bff;
        right: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    .from-me::after {
        background-color: #fff;
        border-bottom-left-radius: 0.5rem;
        right: -41px;
        transform:translate(-30px, -2px);
        width: 10px;
    }

    .from-other {
        background-color: #e5e5ea;
        border-color: #e5e5ea;
        color: #000;
        margin-right: 1rem;
    }

    .from-other::before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid #e5e5ea;
        left: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    .from-other::after {
        background-color: #fff;
        border-bottom-right-radius: 0.5rem;
        left: 19px;
        transform: translate(-30px, -2px);
        width: 10px;
    }

</style>
