<template>
    <div>
        <span class="time">{{ String(hours).padStart(2,"0") }}:{{ String(minutes).padStart(2,"0") }}:{{ String(seconds).padStart(2,"0") }}</span>
    </div>
</template>

<script>
export default {
  name: 'CallTime',
  props: {
    start: Number
  },
  data () {
    return {
      interval: null,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60
      },
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  },
  methods: {
    /**
     * Update timer
     */
    updateDiffs () {
      let diff = Math.abs(Date.now() - this.start);
      this.hours = Math.floor(diff / this.intervals.hour);
      diff -= this.hours * this.intervals.hour;
      this.minutes = Math.floor(diff / this.intervals.minute);
      diff -= this.minutes * this.intervals.minute;
      this.seconds = Math.floor(diff / this.intervals.second);
    }
  },
  created () {
    this.interval = setInterval(() => {
      this.updateDiffs();
    }, 1000);
    this.updateDiffs();
  },
  destroyed () {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
    .time {
        font-family: monospace;
    }
</style>
