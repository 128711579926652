<template>
    <b-input-group>
        <template #append>
            <b-button variant="success" :disabled="sendingMessage || !newMessage" @click="sendMessage">
                <b-spinner v-if="sendingMessage" small></b-spinner>
                <template v-else>
                    <b-icon-cursor-fill></b-icon-cursor-fill>
                </template>
            </b-button>
            <b-button variant="secondary" @click="scrollChatBottom" v-if="layout.chat.showScrollIcon">
                <b-icon-arrow-down-circle-fill></b-icon-arrow-down-circle-fill>
            </b-button>
            <b-button variant="secondary" @click="$store.commit('toggleGuiSetting','expandChat')" v-if="layout.chat.showExpand">
                <b-icon-arrows-expand v-if="!layout.chat.expanded"></b-icon-arrows-expand>
                <b-icon-arrows-collapse v-else></b-icon-arrows-collapse>
            </b-button>
        </template>

        <b-form-input :disabled="sendingMessage" v-model="newMessage" @keydown.enter.native="sendMessage" placeholder="Nachricht eingeben ..." ></b-form-input>
    </b-input-group>
</template>

<script>
import { mapState } from 'vuex';
import scrollChatToBottom from '../helpers/ChatHelper';

export default {
  name: 'ChatMessageSend',

  data () {
    return {
      newMessage: null
    };
  },

  methods: {

    /**
     * Scroll to the bottom of the chat
     */
    scrollChatBottom: function () {
      scrollChatToBottom();
    },

    /**
     * Send new message to alarm server
     */
    sendMessage: function () {
      this.$store.dispatch('chat/sendMessage', this.newMessage).then(() => { this.newMessage = null; });
    }
  },
  computed: {
    ...mapState('chat', [
      // Sending message to the server in progress
      'sendingMessage'
    ]),
    ...mapState([
      'layout'
    ])
  }
};
</script>
