import { Component } from './Component';

export class OwnVideo extends Component {
  constructor (layout, column, withOther = false) {
    super();
    this.left = layout.columns[column].left;
    this.width = layout.columns[column].width;
    this.height = layout.ownVideoHeight;
    this.withOthers = withOther;
    this.hidden = layout.hiddenUser || withOther;
  }
}
