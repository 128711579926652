import { Layout } from './Layout';
import { SopViewer } from './components/SopViewer';
import { VideoArea } from './components/VideoArea';
import { UserStatus } from './components/UserStatus';
import { Chat } from './components/Chat';
import { OwnVideo } from './components/OwnVideo';

/**
 * Layout for portrait mobile screen
 */
export class MobilePortraitLayout extends Layout {
  constructor (width, height, guiSettings, isOwner, participants, hiddenUser) {
    super(width, height, 32, guiSettings, isOwner, participants, hiddenUser);
    this.calcColumns({ left: 1, center: 0, right: 0 });

    this.ownVideoHeight = 150;
    this.chat = new Chat(this, 'left', true).toJson();

    const compactVideo = this.chat.expanded || !this.hideUserStatus;

    const videoArea = new VideoArea(this, 'left');
    videoArea.height = compactVideo ? this.ownVideoHeight : Math.ceil((this.main.height) / (this.hideChat ? 1 : 2));
    this.videoArea = videoArea.toJson();
    this.userStatus = new UserStatus(this, 'left', true).toJson();
    this.sopViewer = new SopViewer(this, 'left', true).toJson();
    this.otherVideos = { hideLabel: this.chat.expanded };
    this.ownVideo = new OwnVideo(this, 'left', true);
  }
}
