<template>
    <div>
        <b-button type="button" block class="btn btn-danger" v-b-modal.modal-alarm :disabled="alarming"><b-icon-bell-fill></b-icon-bell-fill> Weitere Nutzer alarmieren</b-button>
        <b-modal
                id="modal-alarm"
                centered
                title="Weitere Nutzer alarmieren"
                header-bg-variant="danger"
                header-text-variant="white"
                :no-close-on-backdrop="true"
                :hide-footer="step===0 || (step===2 && (alarming || success))"
                @show="alarmModalOpened"
        >
            <div v-if="step === 0">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h4>Gruppe des Gesprächspartners</h4>
                        <p>Wählen Sie eine Gruppe aus</p>
                    </div>
                    <div class="text-right">
                        <b-button
                                variant="outline-dark"
                                :disabled="loadingGroups"
                                @click="loadGroups"
                        >
                            <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                        </b-button>
                    </div>
                </div>

                <b-badge variant="dark" pill>Gesamt</b-badge>&#160;
                <b-badge variant="success" pill>Online</b-badge>&#160;
                <b-badge variant="primary" pill>Extern</b-badge>&#160;
                <b-badge variant="warning" pill>Im Gespräch</b-badge>

                <hr>
                <b-overlay :show="loadingGroups" rounded="sm">
                    <b-list-group>
                        <b-list-group-item
                                button
                                v-for="group in groups"
                                :key="group.id"
                                @click="selectAlarmGroup(group)"
                                class="d-flex justify-content-between align-items-center"
                        >
                            <div class="flex-shrink-1">
                                {{ group.name }}
                            </div>
                            <div class="flex-shrink-0">
                                <b-badge variant="dark" pill>{{ group.total }}</b-badge>&#160;
                                <b-badge variant="success" pill>{{ group.online }}</b-badge>&#160;
                                <b-badge variant="primary" pill>{{ group.external_alarm }}</b-badge>&#160;
                                <b-badge variant="warning" pill>{{ group.inCall }}</b-badge>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-overlay>
            </div>
            <div v-if="step === 1">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <h4>Gruppe: {{ selectedGroup.name }}</h4>
                        <p>Wählen Sie den gewünschten Gesprächspartner aus</p>
                    </div>
                    <div class="text-right">
                        <b-button
                                variant="outline-dark"
                                :disabled="loadingUsers"
                                @click="loadUsers"
                        >
                            <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
                        </b-button>
                    </div>
                </div>
                <hr>
                <b-overlay :show="loadingUsers" rounded="sm">
                    <b-list-group>
                        <b-list-group-item
                                button
                                v-for="user in users"
                                :key="user.id"
                                @click="selectAlarmUser(user)"
                                :disabled="existingUsers.some(existingUser => existingUser.id === user.id)"
                                class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                {{ user.name }}<span v-if="user.sub_name"> - {{ user.sub_name }}</span>
                            </div>
                            <b-badge v-if="existingUsers.some(existingUser => existingUser.id === user.id)" variant="dark" pill>Bereits alarmiert</b-badge>
                            <b-badge v-else-if="user.in_call" variant="primary" pill>Im Gespräch</b-badge>
                            <b-badge v-else-if="user.online" variant="success" pill>Online</b-badge>
                            <b-badge v-else variant="danger" pill>Offline</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-overlay>
            </div>
            <div v-if="step === 2">

                <div class="text-center" v-if="alarming">
                    <b-spinner label="Spinning" style="width: 3rem; height: 3rem;"></b-spinner>
                    <h4 class="mt-3">{{ selectedUser.name}} wird alarmiert</h4>
                </div>

                <div class="text-center" v-else-if="success">
                    <b-icon-check-circle-fill variant="success" font-scale="4"></b-icon-check-circle-fill>
                    <h4 class="mt-3">{{ selectedUser.name}} wurde alarmiert</h4>
                    <b-button block variant="success" @click="$bvModal.hide('modal-alarm')"><b-icon-x-circle-fill></b-icon-x-circle-fill> Schließen</b-button>
                </div>

                <div class="text-center" v-else-if="errorCode === 460">
                    <b-icon-telephone-fill variant="danger" font-scale="4"></b-icon-telephone-fill>
                    <h4 class="mt-3">{{ selectedUser.name}} ist bereits in einem Gespräch</h4>
                    <b-button block variant="danger" @click="alarmUser(true)"><b-icon-bell-fill></b-icon-bell-fill> Trotzdem alarmieren</b-button>
                </div>

                <div class="text-center" v-else-if="errorCode === 461">
                    <b-icon-person-x-fill variant="danger" font-scale="4"></b-icon-person-x-fill>
                    <h4 class="mt-3">{{ selectedUser.name}} ist offline</h4>
                    <b-button block variant="danger" @click="alarmUser(true)"><b-icon-bell-fill></b-icon-bell-fill> Trotzdem alarmieren</b-button>
                </div>

                <div class="text-center" v-else>
                    <b-icon-exclamation-triangle-fill variant="danger" font-scale="4"></b-icon-exclamation-triangle-fill>
                    <h4 class="mt-3">Alarmierung fehlgeschlagen</h4>
                    <b-button block variant="dark" @click="alarmUser"><b-icon-arrow-clockwise></b-icon-arrow-clockwise> Erneut versuchen</b-button>
                </div>

            </div>
            <template #modal-footer>
                <b-button v-if="step === 1" @click="step=0" block><b-icon-arrow-left-square></b-icon-arrow-left-square> Zurück zu Gruppenauswahl</b-button>
                <b-button v-if="step === 2" @click="step=1" block><b-icon-arrow-left-square></b-icon-arrow-left-square> Zurück zu Gesprächspartnerauswahl</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'AlarmUser',

  data () {
    return {
      // Alarm process step
      step: 0,
      // Selected group from which the user wants to alarm
      selectedGroup: undefined,
      // Selected user which the user wants to alarm
      selectedUser: undefined,
      // List of all available user groups
      groups: [],
      // List of all users of the selected group
      users: [],
      // Loading of the groups in progress
      loadingGroups: false,
      // Loading of the users in progress
      loadingUsers: false,
      // Alarm was successful
      success: false,
      // Error during alarm
      errorCode: null
    };
  },

  methods: {

    /**
     * Alarm modal was opened, reset to first step of the alarm process
     */
    alarmModalOpened: function () {
      this.step = 0;
      this.loadGroups();
    },

    /**
     * Load groups of users from alarm server (e.g. clinics, ems, doctors)
     */
    loadGroups: function () {
      this.loadingGroups = true;
      axios.get('group_list').then((response) => {
        this.groups = response.data.data;
      }).catch((error) => {
        // TODO error management
        console.error(error);
      }).finally(() => {
        this.loadingGroups = false;
      });
    },

    /**
     * Load users of a selected group
     */
    loadUsers: function () {
      this.loadingUsers = true;
      axios.get('user_list', { params: { group: this.selectedGroup.id } }).then((response) => {
        this.users = response.data.data;
      }).catch((error) => {
        // TODO error management
        console.error(error);
      }).finally(() => {
        this.loadingUsers = false;
      });
    },

    /**
     * Select group from which the user wants to alarm a user
     * Loads users from the group and continue to the next step of the alarm process
     * @param group
     */
    selectAlarmGroup: function (group) {
      this.selectedGroup = group;
      this.step = 1;
      this.loadUsers();
    },

    /**
     * Select a user which the user wants to alarm
     * Try to alarm the user and continue to the next step of the alarm process
     * @param user
     * @param force Alarm the user even if offline or in another call
     */
    selectAlarmUser: function (user, force = false) {
      this.selectedUser = user;
      this.step = 2;
      this.alarmUser(force);
    },

    /**
     * Alarm the selected user
     * @param force Alarm the user even if offline or in another call
     */
    alarmUser: function (force = false) {
      this.$store.dispatch('userStatus/alarmUser', { user: this.selectedUser, force: force })
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.errorCode = null;
          if (error.response) {
            this.errorCode = error.response.status;
          }
        }
        );
    }
  },

  computed: {
    ...mapState('userStatus', {
      // List of the users, that are already part of the call (disable alarming of these users, use the re-alarm function in the user status list)
      existingUsers: 'users',
      // User is getting alarmed/alarm request is being processed by alarm server
      alarming: 'alarming'
    })
  }
};
</script>
