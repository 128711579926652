<template>
  <div class="error-modal">
    <b-modal
            v-if="error"
            id="error-modal"
            ref="error-modal"
            centered
            :visible="true"
            header-bg-variant="danger"
            header-text-variant="white"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            :hide-header-close="true"
            :hide-footer="!error.button"
            :ok-only="true"
            footer-class="justify-content-center"
            static
    >
        <h5 class="text-danger">#{{ error.code }}</h5>
        <strong>{{ error.message }}</strong>

        <template #modal-title>
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon> {{ error.title }}

        </template>
        <template #modal-footer>
            <b-button onclick="location.reload()"  v-if="error.button" class="mb-2">
                <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon> Try again
            </b-button>
        </template>
      <template #modal-backdrop>
        <div class="modal-backdrop-black"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    error: Object
  }
};
</script>
