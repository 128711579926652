/**
 * Calculate best fitting video grid layout for a given space
 * Implementation idea from https://github.com/oswaldoacauan/bigbluebutton/blob/f6faac564c36c3896f54c1391ce30da3729a3f79/bigbluebutton-html5/imports/ui/components/video-provider/video-list/component.jsx
 * @param areaWidth Area width in px
 * @param areaHeight Area width in px
 * @param aspectRatio Aspect ratio of the grid cells/videos
 * @param videoCount Min. amount of grid cells/videos
 * @param gutter Spacing between grid cells/videos
 * @returns {{videoWidth: number, videoHeight: number, columns: number, rows: number }} Best grid found
 */
export default function findBestVideoGrid (areaWidth, areaHeight, aspectRatio, videoCount, gutter) {
  let bestGrid = { rows: 0, columns: 0, videoWidth: 0, videoHeight: 0 };

  // Try all possible columns amounts searching for the best grid
  for (let columns = 1; columns < videoCount + 1; columns++) {
    const rows = Math.ceil(videoCount / columns);

    // Calculate based on width
    let videoWidth = Math.floor((areaWidth - ((columns - 1) * gutter)) / columns);
    let videoHeight = parseFloat((videoWidth / aspectRatio).toFixed(2));

    let totalVideoWidth = videoWidth * columns + (columns - 1) * gutter;
    let totalVideoHeight = videoHeight * rows + (rows - 1) * gutter;

    // If videos are too high, calculate based on height
    if (totalVideoHeight > areaHeight) {
      videoHeight = Math.floor((areaHeight - ((rows - 1) * gutter)) / rows);
      videoWidth = parseFloat((videoHeight * aspectRatio).toFixed(2));

      totalVideoWidth = videoWidth * columns + (columns - 1) * gutter;
      totalVideoHeight = videoHeight * rows + (rows - 1) * gutter;
    }

    const grid = {
      rows: rows,
      columns: columns,
      areaWidth: totalVideoWidth,
      areaHeight: totalVideoHeight,
      videoWidth: videoWidth,
      videoHeight: videoHeight
    };
    // If the video of the current grid is bigger than in the currently best, overwrite best
    if (bestGrid.videoWidth < grid.videoWidth) { bestGrid = grid; }
  }

  return bestGrid;
}
