import store from '../store/index';

/**
 * Other users as participant of the video room
 */
export default class Participant {
  constructor (connection) {
    // Stream is ready to be viewed
    this.streamReady = false;
    // Video is active
    this.videoActive = false;
    // Audio is active
    this.audioActive = false;
    // Connection id
    this.id = connection.connectionId;
    // Server side data of the user, like the name
    this.data = JSON.parse(connection.data);
  }

  /**
   * Get the video/audio stream
   * @returns {any}
   */
  getStream () {
    return this.subscriber.stream.mediaStream;
  }

  /**
   * Set the stream of the user and subscribe to it
   * @param stream
   */
  setStream (stream) {
    /**
     * Handle completed subscription to stream
     * @param error
     */
    const completionHandler = (error) => {
      if (error) {
        console.error('Error while initializing subscriber: ', error);
      } else {
        this.streamReady = true;
        this.videoActive = this.subscriber.stream.videoActive;
        this.audioActive = this.subscriber.stream.audioActive;
        console.log('Subscriber successfully initialized');
      }
    };

    // Subscribe to stream
    this.subscriber = store.state.room.session.subscribe(stream, undefined, completionHandler);

    // Add event listener for stream property changed, like video and audio is getting disabled
    this.subscriber.on('streamPropertyChanged', (event) => {
      switch (event.changedProperty) {
        case 'videoActive':
          this.videoActive = event.newValue;
          break;
        case 'audioActive':
          this.audioActive = event.newValue;
          break;
      }
    });
  }
}
