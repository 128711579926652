import axios from 'axios';

const state = () => ({
  // Users that are part of the call/alarmed
  users: [],
  // User that is currently alarmed
  alarmUser: null,
  // A user is currently alarmed (alarm request in progress)
  alarming: false
});

const getters = {

};

const actions = {

  /**
   * Alarm additional users to this call
   * @param dispatch
   * @param commit
   * @param state
   * @param user
   * @param force Alarm even if user is offline
   * @returns {Promise<unknown>}
   */
  async alarmUser ({ dispatch, commit, state }, { user, force }) {
    state.alarming = true;
    state.alarmUser = user;
    return new Promise((resolve, reject) => {
      axios.post('alarm/' + user.id, { force: force })
        .then((response) => {
          resolve();
        }).catch((error) => {
          reject(error);
        }).finally(() => {
          state.alarming = false;
          state.alarmUser = null;
        });
    });
  }

};

const mutations = {

  /**
   * Add user to user status list or overwrite if already exists
   * @param state
   * @param newUser
   */
  addOrUpdateUser (state, newUser) {
    if (state.users.some(user => user.id === newUser.id)) {
      state.users.forEach(user => {
        if (user.id === newUser.id && new Date(user.updated_at).getTime() < new Date(newUser.updated_at).getTime()) {
          user.status = newUser.status;
          user.updated_at = newUser.updated_at;
        }
      });
    } else {
      state.users.push(newUser);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
