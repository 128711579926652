import { Layout } from './Layout';
import { SopViewer } from './components/SopViewer';
import { VideoArea } from './components/VideoArea';
import { UserStatus } from './components/UserStatus';
import { Chat } from './components/Chat';
import { OwnVideo } from './components/OwnVideo';

/**
 * Layout for landscape mobile screen
 */
export class MobileLandscapeLayout extends Layout {
  constructor (width, height, guiSettings, isOwner, participants, hiddenUser) {
    super(width, height, 16, guiSettings, isOwner, participants, hiddenUser);
    this.calcColumns({ left: 1 / 2, center: 1 / 2, right: 0 });

    this.ownVideoHeight = this.hiddenUser ? 0 : 150;
    this.chat = new Chat(this, 'left').toJson();
    this.videoArea = new VideoArea(this, 'center').toJson();
    this.userStatus = new UserStatus(this, 'left').toJson();
    this.ownVideo = new OwnVideo(this, 'left');
    this.sopViewer = new SopViewer(this, 'left', true).toJson();
  }
}
