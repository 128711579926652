<template>
    <div style="height: 100%; padding: 10px" class="d-flex flex-column" >

            <b-alert show dismissible variant="primary"><strong>Haftungsausschluss</strong><br>Hersteller und Betreiber sind <strong>nicht</strong> für den Inhalt und die Aktualität der Dokumente verantwortlich.</b-alert>
            <b-form>
                <b-row>
                    <b-col cols="12" xl="6">
                        <b-form-group label="Dokument" label-size="sm" >
                            <multiselect
                                    v-model="document"
                                    :options="config.documents"
                                    :allow-empty="false"
                                    placeholder="Select one"
                                    label="name"
                                    track-by="id"
                                    @input="changeDocument"
                                    :showLabels="false"
                                    :preselectFirst="true"
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12"  xl="6" v-if="pages.length>0">
                        <b-form-group label="Seite" label-size="sm" >
                            <multiselect
                                    :disabled="!documentRendered"
                                    v-model="page"
                                    :options="pages"
                                    :allow-empty="false"
                                    placeholder="Select one"
                                    label="title"
                                    track-by="page"
                                    @input="changePage"
                                    :showLabels="false"
                                    :preselectFirst="true"
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
            <div class="flex-grow-1">
                <vue-pdf-app :id-config="idConfig" :config="pdfConfig" @pages-rendered="pagesRendered" :pdf="pdfFile" style="height: 100%">
                  <template #toolbar-middle-append>
                    <b-button size="sm" class="mx-1" :id="idConfig.zoomIn"><b-icon-zoom-in></b-icon-zoom-in></b-button>
                    <b-button size="sm" class="mx-1" :id="idConfig.zoomOut"><b-icon-zoom-out></b-icon-zoom-out></b-button>
                  </template>
                </vue-pdf-app>
            </div>
            <span v-if="document" class="text-muted text-center" style="font-size: 12px">Hochgeladen am {{ new Date(document.file_changed).toLocaleString() }} von {{ document.author }} </span>

    </div>

</template>
<script>
import VuePdfApp from 'vue-pdf-app';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

export default {
  name: 'SopViewer',
  components: {
    VuePdfApp,
    Multiselect
  },
  data () {
    return {
      // Selected page object (page name and page number)
      page: null,
      // Document was fully rendered by the pdf viewer
      documentRendered: false,
      // Path to the selected pdf file
      pdfFile: null,
      // Selected pdf file, object with metadata, like filename
      document: null,
      // Config for pdf viewer
      pdfConfig: {
        sidebar: false,
        secondaryToolbar: false,
        toolbar: {
          toolbarViewerLeft: false,
          toolbarViewerRight: false,
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: false
          }
        },
        errorWrapper: true
      },
      // Config for custom pdf buttons
      idConfig: { zoomIn: 'zoomInId', zoomOut: 'zoomOutId' }
    };
  },
  methods: {
    /**
     * Set pdf viewer page number and reset zoom
     */
    changePage () {
      this.pdfApp.pdfViewer.currentScaleValue = 'page-fit';
      this.pdfApp.page = this.page.page;
    },

    /**
     * Handle pdf is fully laded, reset zoom and scroll to first page
     * @param pdfApp
     */
    pagesRendered (pdfApp) {
      this.pdfApp = pdfApp;
      this.pdfApp.pdfSidebar.close();
      this.page = this.document.pages[0];
      this.pdfApp.pdfViewer.currentScaleValue = 'page-fit';
      this.changePage();
      // Enable page selection
      this.documentRendered = true;
    },

    /**
     * Handle other file was selected
     */
    changeDocument () {
      this.documentRendered = false;
      this.pdfFile = this.document.url;
    }
  },
  computed: {
    /**
     * List of the pages of the currently loaded document
     * @returns {*[]|*}
     */
    pages: function () {
      if (this.document != null) {
        return this.document.pages;
      }
      return [];
    },
    ...mapState([
      'config'
    ])
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .pdf-app .toolbar {
        z-index: 1 !important;
    }

    .pdf-app.light {
        --pdf-app-background-color: #343a40;
        --pdf-toolbar-color: #FFF;
        --pdf-toolbar-font-color: #6c757d;
        --pdf-button-hover-font-color: #5a6268;
    }

    #toolbarViewerLeft,
    #toolbarViewerRight {
      display: none;
    }
</style>
