import { Component } from './Component';

export class UserStatus extends Component {
  constructor (layout, column, mobile = false) {
    super();

    this.hidden = layout.hideUserStatus;
    this.top = layout.ownVideoHeight;
    this.left = layout.columns[column].left;
    this.width = layout.columns[column].width;
    this.height = layout.main.height - this.top;

    this.showNavIcon = true;
    this.showScrollIcon = !mobile;
    this.showExpand = !mobile;
    this.allowClose = mobile;
  }

  toJson () {
    return {
      top: this.top,
      left: this.left,
      width: this.width,
      height: this.height,
      hidden: this.hidden,
      showNavIcon: this.showNavIcon,
      showScrollIcon: this.showScrollIcon,
      showExpand: this.showExpand,
      allowClose: this.allowClose
    };
  }
}
