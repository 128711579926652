<template>
  <div id="app" v-bind:class="{'big-buttons': guiSettings.bigButtons}" >
      <b-overlay
              :show="!connected"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              no-wrap>
      >
          <template #overlay>
              <div class="text-center">
                  <eta-logo alt="Logo" width="100px"></eta-logo>
                  <h1 class="h3 mb-3 font-weight-normal"><strong>E</strong>mergency <strong>T</strong>alk <strong>A</strong>pplication</h1>
                  <hr>
                  <b-spinner label="Spinning"></b-spinner>
                  <br>
                  Verbinde zu Server ...
              </div>
          </template>
      </b-overlay>

      <template v-if="connected">
          <layout-component :layout="layout" name="menuBar">
            <b-navbar type="dark" variant="dark" class="d-flex justify-content-between menubar">
              <b-navbar-brand>
                  <eta-logo alt="Logo" width="25px" class="mr-2"></eta-logo>
                  ETA
              </b-navbar-brand>
              <div v-if="config.record !== 0" class="bg-danger px-1 rounded">
                  <b-icon-record-circle variant="white" animation="fade"></b-icon-record-circle> <b-icon-mic-fill variant="white"></b-icon-mic-fill> <b-icon-camera-video-fill v-if="config.record === 1" variant="white"></b-icon-camera-video-fill><b-icon-camera-video-off-fill v-else variant="white"> </b-icon-camera-video-off-fill>
              </div>

                <div v-if="hiddenParticipants.length > 0">
                    <b-button variant="warning" size="sm" id="popover-hidden-users">
                        <b-icon-binoculars-fill></b-icon-binoculars-fill>
                    </b-button>
                    <b-popover target="popover-hidden-users" triggers="click" placement="bottom">
                        <template #title>Beobachter</template>
                        <span v-for="hiddenParticipant in hiddenParticipants" v-bind:key="hiddenParticipant.id">{{ hiddenParticipant.data.name }}  {{ hiddenParticipant.data.sub_name ? " - "+hiddenParticipant.data.sub_name:"" }}</span>
                    </b-popover>
                </div>

                <div v-if="otherAlarms.length > 0">
                    <b-button variant="danger" size="sm" id="popover-calls">
                        <b-icon-telephone-inbound-fill></b-icon-telephone-inbound-fill>
                    </b-button>
                    <b-popover target="popover-calls" triggers="click" placement="bottom">
                        <b-card v-for="alarm in otherAlarms" v-bind:key="alarm.id" header-class="bg-danger text-white p-2" body-class="p-2">
                            <template #header>
                                <div class="d-flex flex-grow-1 align-items-baseline">
                                    <strong class="mr-auto">{{ alarm.name }} </strong>
                                    <small class="ml-2">{{ new Date(alarm.created_at).toLocaleTimeString() }}</small>
                                </div>
                            </template>
                            <strong>{{ alarm.reason }}</strong>
                            <new-alarm-feedback @join="joinCall(alarm)" @feedback="sendFeedback(alarm, $event)" :alarm="alarm"></new-alarm-feedback>
                        </b-card>
                    </b-popover>
                </div>

              <div v-if="guiSettings.showTime" class="bg-white text-danger px-1 rounded">
                  <call-time :start="config.timestamp"></call-time>
              </div>

              <div>
                <b-button variant="primary" class="mr-3" v-if="fullscreenSupported && !fullscreenEnabled" @click="openFullscreen">
                  <b-icon-arrows-fullscreen variant="white"></b-icon-arrows-fullscreen>
                </b-button>
                <b-button variant="primary" class="mr-3" v-if="fullscreenSupported && fullscreenEnabled" @click="closeFullscreen">
                  <b-icon-fullscreen-exit variant="white"></b-icon-fullscreen-exit>
                </b-button>

                <b-button variant="danger" @click="close">
                  <b-icon-x-circle-fill variant="white"></b-icon-x-circle-fill>
                </b-button>

              </div>

          </b-navbar>
          </layout-component>
          <div v-if="layout.menuBar.hidden && config.record !== 0" class="bg-danger p-1 rounded-bottom rounded-right recording">
              <b-icon-record-circle variant="white" animation="fade"></b-icon-record-circle> <b-icon-mic-fill variant="white"></b-icon-mic-fill> <b-icon-camera-video-fill v-if="config.record === 1" variant="white"></b-icon-camera-video-fill><b-icon-camera-video-off-fill v-else variant="white"> </b-icon-camera-video-off-fill>
          </div>
          <layout-component :layout="layout" name="main">

              <layout-component :layout="layout" name="ownVideo" style="padding: 10px">
                  <div class="rounded shadow p-1 w-100 h-100 border">
                  <participant-component :participant="ownUser" :own="true" name="Ich" ></participant-component>
                  </div>
              </layout-component>
              <layout-component :layout="layout" name="chat" style="padding: 10px">
                  <div class="chat-wrapper rounded shadow p-2 border">
                      <div class="chat-list">
                          <chat-message-list></chat-message-list>
                      </div>
                      <div class="chat-actions">
                        <chat-message-send></chat-message-send>
                      </div>
                  </div>
              </layout-component>

              <layout-component :layout="layout" name="userStatus" style="padding: 10px;">
                  <div class="user-status-wrapper rounded shadow p-2 border">
                      <div class="user-status-list">
                          <user-status></user-status>
                      </div>
                      <div class="user-status-actions">
                        <alarm-user v-if="isOwner"></alarm-user>
                      </div>
                  </div>
              </layout-component>

              <layout-component :layout="layout" name="videoArea" style="padding: 10px;">
                  <div id="videoList" class="d-flex align-items-center w-100 h-100 justify-content-center" >
                      <div :style="videoGridStyle">

                          <div :style="cellStyle" v-if="layout.ownVideo.withOthers">
                              <div class="rounded shadow p-1 w-100 h-100 border">
                            <participant-component :participant="ownUser" :own="true" name="Ich" ></participant-component>
                              </div>
                          </div>

                          <div :style="cellStyle" v-for="participant in participants" v-bind:key="participant.id">
                              <div class="rounded shadow p-1 w-100 h-100 border">
                              <participant-component :participant="participant" :footer-controls="false" ></participant-component>
                              </div>
                          </div>

                          <div v-if="participants.length === 0" :style="cellStyle">
                              <div class="w-100 h-100 d-flex align-items-center text-center justify-content-center">
                                  <div>
                                      <b-spinner label="Loading..."></b-spinner>
                                      <br>
                                      Warten auf weitere Teilnehmer
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </layout-component>
              <layout-component :layout="layout" name="sopViewer" style="padding: 10px">
                  <sop-viewer class="rounded shadow p-2 border"></sop-viewer>
              </layout-component>
          </layout-component>
          <layout-component :layout="layout" name="mediaControl">
            <media-control style="width: 100%"></media-control>
          </layout-component>

          <layout-component :layout="layout" name="footer">
              <footer class="footer mt-auto bg-light">
                  <div class="container text-center" style="font-size: 0.7rem;">
                      <span><not-md-icon width="12" class="align-baseline"></not-md-icon> Kein Medizinprodukt <manufacturer-icon width="8" class="align-baseline" ></manufacturer-icon> Fachbereich Gesundheit, Wiesenstraße 14, 35390 Gießen, Germany</span>
                  </div>
              </footer>
          </layout-component>

      </template>
      <error-modal :error="error"></error-modal>

      <b-toast :id="'alarm-toast-'+alarm.id" v-for="alarm in otherAlarms" v-bind:key="alarm.id" solid :visible="true" :no-auto-hide="true" header-class="bg-danger text-white white-close" >
          <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-baseline">
                  <strong class="mr-auto">Neuer Alarm </strong>
                  <small class="mx-2">{{ new Date(alarm.created_at).toLocaleTimeString() }}</small>
              </div>
          </template>
          <strong>Anrufer</strong>: {{ alarm.name }}<br>
          <strong>Anrufgrund</strong>: {{ alarm.reason }}<br>
          <new-alarm-feedback class="mt-3" @join="joinCall(alarm)" @feedback="sendFeedback(alarm, $event)" :alarm="alarm"></new-alarm-feedback>
      </b-toast>

  </div>
</template>

<script>
import ErrorModal from './components/ErrorModal';
import ParticipantComponent from './components/ParticipantComponent';
import MediaControl from './components/MediaControl';
import axios from 'axios';
import UserStatus from './components/UserStatus';
import CallTime from './components/CallTime';
import { mapState, mapGetters } from 'vuex';
import AlarmUser from './components/AlarmUser';
import Myself from './models/Myself';
import Participant from './models/Participant';
import { ConfigError } from './error';
import ChatMessageList from './components/ChatMessageList';
import ChatMessageSend from './components/ChatMessageSend';
import SopViewer from './components/SopViewer';
import LayoutComponent from './components/LayoutComponent';
import findBestVideoGrid from './helpers/VideoGrid';
import NewAlarmFeedback from './components/NewAlarmFeedback';
import EtaLogo from './assets/logo.svg';
import NotMdIcon from './assets/notmdicon.svg';
import ManufacturerIcon from './assets/manufacturer.svg';

const ShaJs = require('sha.js');

export default {

  components: {
    NewAlarmFeedback,
    LayoutComponent,
    SopViewer,
    ChatMessageSend,
    ChatMessageList,
    AlarmUser,
    CallTime,
    UserStatus,
    MediaControl,
    ErrorModal,
    ParticipantComponent,
    EtaLogo,
    NotMdIcon,
    ManufacturerIcon
  },

  name: 'App',
  data () {
    return {
      // Application error during loading
      error: null,
      // Spacing between videos
      gutter: 5,
      // Aspect ratio of the videos
      aspect_ratio: 408 / 315,
      // Interval to keep session with api server alive
      keepAliveInterval: undefined,
      // List of other parallel alarms
      otherAlarms: [],
      // Is full screen supported by the browser
      fullscreenSupported: false,
      // is full screen active
      fullscreenEnabled: false
    };
  },
  methods: {

    close: function () {
      this.disconnect();
      window.close();
    },

    /**
       * Open a new window with the web client
       * @param call
       */
    joinCall: function (call) {
      call.status = 'joined';
      window.open(call.web_gui + '#callback=' + encodeURIComponent(call.callback) + '&callToken=' + encodeURIComponent(call.call_token), '_blank');
    },

    /**
       * Send feedback (accept/decline) to the server for a new call
       * @param call call the feedback belongs to
       * @param status new feedback status
       */
    sendFeedback: function (call, status) {
      axios('call_feedback', { method: 'post', data: { status: status }, headers: { Authorization: 'CallToken ' + call.call_token } })
        .then((response) => {
          // Feedback was updated
          this.overwriteCall(call, response.data.data);
        }).catch((error) => {
          if (error.response) {
            // The feedback was already changed, update call
            if (error.response.status === 409) {
              this.overwriteCall(call, error.response.data.data);
            }
          }
        });
    },

    /**
       * Update call with new data
       * @param oldCall
       * @param newCall
       */
    overwriteCall (oldCall, newCall) {
      oldCall.status = newCall.status;
      oldCall.updated_at = newCall.updated_at;
    },

    /**
     * Load config from url or cookie
     * @returns {Promise<unknown>}
     */
    setConfigFromUrl: function () {
      return new Promise((resolve, reject) => {
        console.info('Loading config');
        // Check if url hash exists, if not fail
        if (window.location.hash === '') {
          console.error('Url hash is empty');
          reject(new ConfigError(900, 'configuration not found'));
          return;
        }

        // Extract hash content
        const hash = window.location.hash.substr(1);
        let params = {};
        hash.split('&').map(hk => {
          const temp = hk.split('=');
          params[temp[0]] = decodeURIComponent(temp[1]);
        });

        // If the callback and call token is not in the url, try to get this information from the cookie
        if (params.callback === undefined || params.callToken === undefined) {
          console.debug('Url hash callback or call token is empty, trying session');
          // If session is missing, throw error
          if (params.session === undefined) {
            console.error('Url hash session is missing');
            reject(new ConfigError(902, 'configuration missing callback or call token'));
            return;
          }

          // Try to get cookie with the session name, if not found remove session from url and throw error
          params = this.$cookies.get('eta-call-session-' + params.session);
          if (params == null) {
            window.location.hash = '';
            console.error('No cookie found with the given session');
            reject(new ConfigError(905, 'session not found'));
            return;
          }

          console.debug('Found cookie, try to get callback and token');

          try {
            // If session content is missing the callback or call token, throw error and remove cookie and session from url
            if (params.callback === undefined || params.callToken === undefined) {
              console.error('Callback or call token not found in the cookie');
              this.$cookies.remove('eta-call-session' + params.session);
              window.location.hash = '';
              reject(new ConfigError(907, 'session configuration missing callback or call token'));
              return;
            }
          } catch (e) {
            // If reading the session content failed, throw error and remove cookie and session from url
            console.error('Reading callback and call token from cookie failed');
            this.$cookies.remove('eta-call-session' + params.session);
            window.location.hash = '';
            reject(new ConfigError(906, 'session invalid'));
            return;
          }
          console.debug('Extracted callback and call token from cookie');
        } else {
          console.debug('Extracted callback and call token from url');
        }

        // Store callback and call token in a cookie with a hash of the session to allow multiple tabs with different calls
        // and hide the call token from the url, so the user can't easily copy the url and send to someone else
        const sessionHash = ShaJs('sha256').update(params.callback + params.callToken).digest('hex');
        this.$cookies.set('eta-call-session-' + sessionHash, JSON.stringify({ callback: params.callback, callToken: params.callToken }));
        window.location.hash = 'session=' + sessionHash;
        console.debug('Stored callback and call token in cookie');

        // Setup axios
        axios.defaults.baseURL = params.callback + '/api/session/';
        axios.defaults.headers.common.Authorization = 'CallToken ' + params.callToken;

        console.debug('Load config from alarm-server');
        // Get config from api server
        axios.get('config')
          .then(response => {
            console.debug('Config received from alarm-server');
            // Set config in state management
            this.$store.commit('setConfig', {
              openViduToken: response.data.openViduToken,
              record: response.data.record,
              keepChat: response.data.keepChat,
              myself: response.data.myself,
              owner: response.data.owner,
              timestamp: response.data.timestamp,
              documents: response.data.documents,
              loaded: true
            });

            // Overwrite gui settings value by value
            if (response.data.guiSettings != null) {
              const guiSettings = Object.entries(JSON.parse(response.data.guiSettings));
              guiSettings.forEach(setting => {
                this.$store.commit('setGuiSetting', { setting: setting[0], value: setting[1] });
              });
            }

            // If no document present, hide the sop viewer
            if (response.data.documents.length === 0) {
              this.$store.commit('setGuiSetting', { setting: 'hideSopViewer', value: true });
            }

            // Add messages to chat
            response.data.messages.forEach(message => this.$store.commit('chat/addMessage', message));
            // Add user status
            response.data.users.forEach(user => this.$store.commit('userStatus/addOrUpdateUser', user));

            resolve();
          }).catch(error => {
            console.debug('Loading config from alarm-server failed');
            // Config is invalid
            if (error.response && error.response.status === 401) {
              reject(new ConfigError(401, 'configuration call token invalid'));
            } else {
              reject(error);
            }
          });
      });
    },

    /**
     * Listen to different events occurring in the room
     * @param event
     */
    roomEventHandler: function (event) {
      switch (event.type) {
        case 'user-joined':
          this.makeToast('info', 'Raum beigetreten', event.username + ' hat den Raum betreten');
          break;
        case 'user-left':
          this.makeToast('warning', 'Raum verlassen', event.username + ' hat den Raum verlassen');
          break;
        case 'reconnect_failed':
          this.makeToast('danger', 'Verbindung verloren', 'Wiederherstellung fehlgeschlagen');
          break;
        case 'reconnecting':
          this.makeToast('warning', 'Verbindung verloren', 'Versuche Verbindung wiederherzustellen');
          break;
        case 'reconnected':
          this.makeToast('success', 'Verbindung wiederhergestellt', 'Alles sollte wieder funktionieren');
          break;
        case 'message':
          this.$store.commit('chat/addMessage', event.message);
          break;
        case 'message-confirmation':
          this.$store.commit('chat/addConfirmation', event.confirmation);
          break;
        case 'user-update':
          this.$store.commit('userStatus/addOrUpdateUser', event.user);
          break;
        case 'new-user':
          this.$store.commit('userStatus/addOrUpdateUser', event.user);
          break;
        case 'new-alarm':
          this.otherAlarms = this.otherAlarms.filter(alarm => alarm.id !== event.alarm.id);
          this.otherAlarms.push(event.alarm);
          this.$bvToast.show('alarm-toast-' + event.alarm.id);
          // Confirmation of received alarm is not yet send
          if (event.alarm.status === 'alarmed') {
            this.sendFeedback(event.alarm, 'received');
          }
          break;
        case 'unsupported-browser':
          this.error = { code: 904, title: 'Browser', message: 'Ihr Browser wird nicht unterstützt.' };
          break;
      }
    },

    /**
     * Listen to different events of the current user
     * @param event
     */
    ownEventHandler: function (event) {
      switch (event.type) {
        case 'videoAudioError':
          if (event.error.name === 'INPUT_VIDEO_DEVICE_NOT_FOUND') {
            this.makeToast('danger', 'Video Fehler', 'Kamera nicht gefunden');
          } else if (event.error.name === 'INPUT_AUDIO_DEVICE_NOT_FOUND') {
            this.makeToast('danger', 'Audio Fehler', 'Mikrofon nicht gefunden');
          } else if (event.error.name === 'DEVICE_ACCESS_DENIED') {
            this.makeToast('danger', 'Fehler', 'Zugriff verweigert');
          } else {
            this.makeToast('danger', 'Fehler', event.error.name);
          }
          break;
      }
    },

    /**
     * Create a new toast message
     * @param variant
     * @param header
     * @param body
     */
    makeToast (variant, header, body) {
      this.$bvToast.toast(body, {
        title: header,
        variant: variant,
        solid: true
      });
    },

    /**
     * Update the layout
     */
    updateLayout () {
      this.$store.commit('updateLayout');
    },

    /**
     * Update the fullscreen status, called by event listener
     */
    fullscreenChange (event) {
      if (document.fullscreenElement !== undefined) {
        this.fullscreenEnabled = (document.fullscreenElement !== null);
      } else if (document.webkitFullscreenElement !== undefined) {
        this.fullscreenEnabled = (document.webkitFullscreenElement !== null);
      }
    },

    /**
     * Open full screen view
     */
    openFullscreen () {
      const target = document.getElementsByTagName('html')[0];
      if (target.requestFullscreen) {
        target.requestFullscreen();
      } else if (target.webkitRequestFullscreen) {
        target.webkitRequestFullscreen();
      }
    },

    /**
     * Close full screen view
     */
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },


    /**
     * Connect to OpenVidu Room
     */
    connectRoom: function () {
      // Initialize the room
      this.$store.commit('room/init');

      // -- Setup the session listeners --
      this.session.on('connectionCreated', (event) => {
        // If the local user is not set yet, use the first connection created as the own user
        if (this.ownUser == null) {
          this.$store.commit('room/setOwnUser', new Myself(event.connection));
          this.ownUser.setEventHandler(this.ownEventHandler);
        } else {
          // Create new Participant
          const participant = new Participant(event.connection);
          // If the user visible, add to room
          if (!participant.data.hidden) {
            this.$store.commit('room/addParticipant', participant);
            // If participant is a normal user, show alert
            if (participant.data.type === 'WEBRTC') {
              this.roomEventHandler({
                type: 'user-joined',
                username: participant.data.name + (participant.data.sub_name ? ' - ' + participant.data.sub_name : '')
              });
            }
          } else {
            // If user is hidden, add to list of hidden users
            this.$store.commit('room/addHiddenParticipant', participant);
          }
        }
      });
      this.session.on('streamCreated', (event) => {
        this.$store.commit('room/addStream', event.stream);
      });
      this.session.on('connectionDestroyed', (event) => {
        // Show alert if participant is a normal user and left
        this.participants.forEach((participant) => {
          if (participant.id === event.connection.connectionId && participant.data.type === 'WEBRTC') {
            this.roomEventHandler({ type: 'user-left', username: participant.data.name + (participant.data.sub_name ? ' - ' + participant.data.sub_name : '') });
          }
        });
        // Remove participant from the list of participants or hidden participants
        this.$store.commit('room/removeParticipant', event.connection);
      });
      this.session.on('reconnecting', () => {
        this.roomEventHandler({ type: 'reconnecting' });
      });
      this.session.on('reconnected', () => {
        console.warn('reconnected');
        this.disconnect();
        this.connect().then(() => {
          this.roomEventHandler({ type: 'reconnected' });
        });
      });
      this.session.on('sessionDisconnected', (event) => {
        // Session was disconnected due to network issues and repair failed
        if (event.reason === 'networkDisconnect') {
          this.roomEventHandler({ type: 'reconnect_failed' });
          console.warn('sessionDisconnected');
          // Try to reset connection and try again
          this.disconnect();
          this.connect();
        } else {
          // Disconnected from the session for other reason than a network drop
        }
      });
      this.session.on('signal:CHAT_MESSAGE', (event) => {
        this.roomEventHandler({ type: 'message', message: JSON.parse(event.data) });
      });
      this.session.on('signal:CHAT_MESSAGE_CONFIRMATION', (event) => {
        this.roomEventHandler({ type: 'message-confirmation', confirmation: JSON.parse(event.data) });
      });
      this.session.on('signal:USER_UPDATE', (event) => {
        this.roomEventHandler({ type: 'user-update', user: JSON.parse(event.data) });
      });
      this.session.on('signal:NEW_USER', (event) => {
        this.roomEventHandler({ type: 'new-user', user: JSON.parse(event.data) });
      });
      this.session.on('signal:NEW_ALARM', (event) => {
        this.roomEventHandler({ type: 'new-alarm', alarm: JSON.parse(event.data) });
      });

      // Connect to OpenVidu Room
      this.session.connect(this.config.openViduToken)
        .then(() => {
          // Set room connected status to true, disabled loading overlay
          this.$store.commit('room/setConnected', true);
          // Disconnect from server before leaving page
          window.onbeforeunload = () => {
            this.disconnect();
          };
        })
        .catch((e) => {
          if (e.name === 'BROWSER_NOT_SUPPORTED') {
            this.roomEventHandler({ type: 'unsupported-browser' });
          } else {
            console.error(e);
          }
        });
    },

    /**
     * Connect to Alarm-Server and OpenVidu-Server
     * @returns {Promise<unknown>}
     */
    connect () {
      return new Promise((resolve, reject) => {
        this.setConfigFromUrl().then(() => {
          console.info('Connected to Alarm-Server');
          this.connectRoom();
          this.updateLayout();
          this.keepAliveInterval = window.setInterval(() => {
            axios.get('keep-alive');
          }, 60000);
          resolve();
        }).catch((e) => {
          // Loading config failed
          if (e instanceof ConfigError) {
            switch (e.code) {
              case 900:
                this.error = {
                  code: 900,
                  title: 'Konfiguration',
                  message: 'Die Konfiguration wurde nicht gefunden.'
                };
                break;
              default:
                this.error = {
                  code: e.code,
                  title: 'Konfiguration',
                  message: 'Die Konfiguration ist ungültig.'
                };
                break;
            }
          } else {
            console.error(e);
            this.error = {
              code: 903,
              title: 'Fehler',
              message: 'Beim Laden der Anwendung ist ein Fehler aufgetreten.'
            };
          }
        });
      });
    },

    /**
     * Disconnect from Alarm-Server and OpenVidu-Server
     */
    disconnect () {
      // Remove the keep alive interval
      clearInterval(this.keepAliveInterval);
      // Disconnect from OpenVidu
      this.session.disconnect();
      // Reset room to initial state
      this.$store.commit('room/reset');
    }
  },

  created () {
    // Add listeners for screen size and orientation changes
    window.addEventListener('resize', this.updateLayout);
    window.addEventListener('orientationchange', () => { setTimeout(this.updateLayout, 100); });
    screen.orientation.addEventListener('change', () => { setTimeout(this.updateLayout, 100); });

    window.addEventListener('webkitfullscreenchange', this.fullscreenChange);
    window.addEventListener('fullscreenchange', this.fullscreenChange);

    if (document.fullscreenEnabled) {
      this.fullscreenSupported = document.fullscreenEnabled;
    } else if (document.webkitFullscreenEnabled) {
      this.fullscreenSupported = document.webkitFullscreenEnabled;
    }

    // Update layout, as the screen size is now set
    this.updateLayout();
  },

  destroyed () {
    // Remove listener for layout changed
    window.removeEventListener('resize', this.updateLayout);
    // Remove interval for keep alive messages
    clearInterval(this.keepAliveInterval);
  },

  computed: {
    ...mapState([
      'config',
      'guiSettings',
      'layout'
    ]),

    ...mapGetters([
      'isOwner'
    ]),

    ...mapState('room', [
      'participants',
      'hiddenParticipants',
      'connected',
      'ownUser',
      'session'
    ]),

    /**
     * Get style for the video grid
     * @returns {{gridTemplateRows: string, gridTemplateColumns: string, display: string, width: string, "grid-auto-flow": string, "justify-content": string, "grid-gap": string, height: string}}
     */
    videoGridStyle: function () {
      return {
        'grid-auto-flow': 'dense',
        'grid-gap': this.gutter + 'px',
        'justify-content': 'center',
        display: 'grid',
        width: this.optimalGrid.areaWidth + 'px',
        height: this.optimalGrid.areaHeight + 'px',
        gridTemplateColumns: 'repeat(' + this.optimalGrid.columns + ', 1fr)',
        gridTemplateRows: 'repeat(' + this.optimalGrid.rows + ', 1fr)'
      };
    },

    /**
     * Style for each cell of the video grid
     * @returns {{width: string, height: string}}
     */
    cellStyle: function () {
      return {
        width: this.optimalGrid.videoWidth + 'px',
        height: this.optimalGrid.videoHeight + 'px'
      };
    },

    /**
     * Get the optimal grid for the videos
     * @returns {{videoWidth: number, videoHeight: number, columns: number, rows: number}|*}
     */
    optimalGrid: function () {
      let gridCount = this.participants.length;
      // If no user online, set grid to 1 to give space for the loading animation
      if (gridCount === 0) { gridCount = 1; }
      // If the users own image should be with the other users, add grid to the own video
      if (this.layout.ownVideo.withOthers) { gridCount++; }

      // Find best grid to the given space
      return findBestVideoGrid(this.layout.videoArea.width - 20, this.layout.videoArea.height - 20, this.aspect_ratio, gridCount, this.gutter);
    }
  },

  /**
   * Starting application
   */
  mounted () {
    // Prevent scale gestures
    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) event.preventDefault();
    });

    // Connect to server
    this.connect();

    // Watch gui Settings and update layout on changes
    this.$store.watch(
      (state) => {
        return this.$store.state.guiSettings;
      },
      (newValue, oldValue) => {
        console.debug('gui changed');
        this.updateLayout();
      },
      {
        deep: true
      }
    );
  },

  watch: {
    /**
     * Watch for participants amount changes
     * Switch from chat to user status if an other user is online
     */
    participants: function () {
      if (this.participants.length === 0) {
        this.$store.commit('setGuiSetting', { setting: 'hideChat', value: true });
        this.$store.commit('setGuiSetting', { setting: 'hideUserStatus', value: false });
      } else {
        this.$store.commit('setGuiSetting', { setting: 'hideChat', value: false });
        this.$store.commit('setGuiSetting', { setting: 'hideUserStatus', value: true });
      }
    }
  }
};
</script>
<style lang="scss">
    // Import Bootstrap and BootstrapVue source SCSS files
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~bootstrap-vue/src/index.scss';
    @import "./assets/custom.scss";
</style>
