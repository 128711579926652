import { Component } from './Component';

export class MenuBar extends Component {
  constructor (layout) {
    super();
    this.hidden = layout.guiSettings.hideMenuBar;
    this.width = layout.width;
    this.height = 50;
  }
}
