/**
 * Abstract component class
 */
export class Component {
  get width () {
    return this.hidden ? 0 : this._width;
  }

  set width (value) {
    this._width = value;
  }

  get height () {
    return this.hidden ? 0 : this._height;
  }

  set height (value) {
    this._height = value;
  }

  get left () {
    return this.hidden ? 0 : this._left;
  }

  set left (value) {
    this._left = value;
  }

  toJson () {
    return {
      top: this.top,
      left: this.left,
      width: this.width,
      height: this.height,
      hidden: this.hidden
    };
  }

  top = 0;
  _left = 0;
  _width = 0;
  _height = 0;
  hidden = false;
}
