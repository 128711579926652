import { Component } from './Component';

export class VideoArea extends Component {
  constructor (layout, column) {
    super();

    this.left = layout.columns[column].left;
    this.width = layout.columns[column].width;
    this.height = layout.main.height;
  }
}
