import { Component } from './Component';

export class Footer extends Component {
  constructor (layout, height) {
    super();
    this.width = layout.width;
    this.height = height;
    this.top = layout.height - this.height;
  }
}
